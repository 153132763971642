<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card title="Nouvelle actualité">
          <b-row class="mb-2">
            <b-col sm="6">
              <b-form-group label="Titre">
                <b-form-input
                  v-model="$v.title.$model"
                  type="text"
                  :class="{ 'is-invalid': $v.title.$error }"
                  placeholder="Titre de l'actualité"
                />
                <div
                  v-if="$v.title.$error"
                  class="invalid-feedback"
                >
                  <span
                    v-if="!$v.title.required"
                  >Le titre est requis.</span>
                </div>
              </b-form-group>
            </b-col>

            <b-col
              sm="6"
            >
              <b-form-group label="Image(s) illustrative(s)">
                <b-form-file
                  v-model="$v.files.$model"
                  multiple
                  browse-text="Parcourir"
                  placeholder="Choisir une ou plusieurs images"
                  accept=".jpg, .jpeg, .png"
                  :file-name-formatter="formatNames"
                  :class="{ 'is-invalid': $v.files.$error }"
                />
                <div
                  v-if="$v.files.$error"
                  class="invalid-feedback"
                >
                  <span
                    v-if="!$v.files.required"
                  >L'image est requise.</span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <quill-editor
            ref="myEditor"
            v-model="$v.content.$model"
            :options="snowOption"
            :class="{ 'is-invalid': $v.content.$error }"
          />
          <div
            v-if="$v.content.$error"
            class="invalid-feedback"
          >
            <span
              v-if="!$v.content.required"
            >La Description est requise.</span>
          </div>

          <b-col
            sm="12"
            class="mt-2"
          >
            <div class="text-right">
              <b-button
                variant="light"
                :disabled="process"
                @click="reset"
              >
                Rafraichir
              </b-button>
              <b-button
                variant="success"
                class="ml-2"
                type="submit"
                :disabled="process"
                @click="formSubmit"
              >
                <b-spinner
                  v-if="process"
                  small
                />
                <span v-show="!process">Ajouter</span>
              </b-button>
            </div>
          </b-col>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['clean'],
]

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
        placeholder: 'Description de l\'actualité',
        modules: {
          toolbar: toolbarOptions,
        },
      },
      snowOption2: {
        theme: 'bubble',
        syntax: false,
        readOnly: true,
      },
      title: null,
      content: null,
      contentHtml: '',
      contents: "Toto je ne viendrai pas aujourd'hui.\n",
      files: [],
    }
  },
  validations: {
    title: {
      required,
    },
    content: {
      required,
    },
    files: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      success: 'newsSuccess',
      error: 'newsError',
      process: 'newsProcess',
    }),
  },
  watch: {
    content() {
      this.contentHtml = this.$refs.myEditor.quill.root.innerHTML
    },
    success(val) {
      if (val) {
        switch (val.valid) {
          case 1:
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Actualité',
                text: val.message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
            break
          default:
            this.reset()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Actualité',
                text: val.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            break
        }
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Actualité',
            text: 'Une erreur est survenue.',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['addNews']),
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name
      }
      return `${files.length} images sélectionnées`
    },
    reset() {
      this.files = []
      this.title = null
      this.content = null
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    formSubmit() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      this.addNews({
        title: this.title,
        description: this.contentHtml,
        limit_date: null,
        file: this.files,
      })
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
